/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  ScrollView,
  Image,
  Alert,
  ActivityIndicator,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import PromoBanner from '../components/PromoBanner';
import ProgressView from '../components/ProgressView';
import DynamicText from '../components/DynamicText';

import {Modal} from '../classes/Modal';
import {colors} from '../classes/Colors';
import {firebase} from '../classes/Firebase';
import DataController from '../classes/DataController';
import PlatformController from '../classes/PlatformController';
import RemoteDataController from '../classes/RemoteDataController';
import NavigationController from '../navigation/NavigationController';

import {
  greggsPromotionStrings,
  getStringsForPromoCard,
} from '../constants/greggsPromotion';

var _ = require('lodash');
var moment = require('moment');
const timer = require('react-native-timer');

type Props = {};

type State = {
  quizSet: {},
  quiz: Object,
  questions: [],
  results: [],
  showInDepthResults: boolean,
  loadingCertificate: boolean,
  sendingPGDAuthorisation: boolean,
  shouldShowPGDDialogue: boolean,
  quizCanBeUsedToAuthorise: boolean,
};

export default class QuizResultsScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var comingFromQuiz = navigation.getParam('comingFromQuiz', false);
    var comingFromOverviewScreen = navigation.getParam(
      'comingFromOverviewScreen',
      true,
    );
    var backButtonText = 'Back';

    if (comingFromQuiz && comingFromOverviewScreen) {
      backButtonText = 'Overview';
    }

    var leftButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('backButtonTapped')}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 10,
            }}>
            <Image
              style={{width: 12, height: 21}}
              source={require('../assets/Button_iOS_Back.png')}
            />
            <Text style={{color: '#FFF', marginLeft: 6, fontSize: 17}}>
              {backButtonText}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );

    return {
      title: 'Results',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerLeft: leftButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      var showInDepthResults = false;
      var shouldShowPGDDialogue = false;

      var comingFromQuiz = this.props.navigation.getParam(
        'comingFromQuiz',
        false,
      );
      var quizSet = this.props.navigation.getParam('quizSet', {});
      var questions = this.props.navigation.getParam('questions', []);
      var results = this.props.navigation.getParam('results', []);
      var quiz = this.props.navigation.getParam('quiz', {});

      // update passed in quizSet to make sure it's latest version
      for (
        let quizSetIndex = 0;
        quizSetIndex < this.props.screenProps.quizSets.length;
        quizSetIndex++
      ) {
        var aQuizSet = this.props.screenProps.quizSets[quizSetIndex];
        if (aQuizSet.name === quizSet.name) {
          quizSet = aQuizSet;
          break;
        }
      }

      var correctCount = 0;

      for (
        var resultIndex = 0, resultsLength = results.length;
        resultIndex < resultsLength;
        resultIndex++
      ) {
        var result = results[resultIndex];
        if (result.correct) {
          correctCount++;
        }
      }

      if (comingFromQuiz === false) {
        showInDepthResults = true;
      }

      let quizCanBeUsedToAuthorise = true;

      // only show pgd dialogue if pgd and not acknowledged
      if (quizSet.pgd && quizSet.pgdInfo.acknowledged !== true) {
        // only show pgd dialogue if got all questions correct
        if (correctCount === questions.length) {
          if (quizSet.pma === true) {
            // PMA quizzes expire after 1 year - so ignore quizzes that are older than a year when deciding if result can be used
            let isOlderThan1Year =
              moment().diff(moment(quiz.time), 'years') > 0;
            if (isOlderThan1Year) {
              quizCanBeUsedToAuthorise = false;
            }
          }

          if (quizCanBeUsedToAuthorise) {
            shouldShowPGDDialogue = true;
          }
        }
      }

      this.state = {
        quizSet: quizSet,
        quiz: quiz,
        questions: questions,
        results: results,
        showInDepthResults: showInDepthResults,
        loadingCertificate: false,
        sendingPGDAuthorisation: false,
        shouldShowPGDDialogue: shouldShowPGDDialogue,
        quizCanBeUsedToAuthorise: quizCanBeUsedToAuthorise,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    console.log(this.state);

    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');

      // check if PGD is now authorised
      this.checkPGDStatus();
    }

    var that = this;

    timer.setInterval('pgdStatusTimer', this.checkPGDStatus.bind(this), 10000);

    setTimeout(() => {
      that.props.navigation.setParams({
        backButtonTapped: that.backButtonTapped.bind(that),
      });
    }, 100);
  }

  componentWillUnmount() {
    timer.clearInterval('pgdStatusTimer');
  }

  componentDidUpdate(prevProps) {
    if (this.props.screenProps.quizSets !== prevProps.screenProps.quizSets) {
      for (
        let quizSetIndex = 0;
        quizSetIndex < this.props.screenProps.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.props.screenProps.quizSets[quizSetIndex];
        if (quizSet.name === this.state.quizSet.name) {
          this.setState({quizSet: quizSet});
          break;
        }
      }
    }
  }

  async checkPGDStatus() {
    if (
      this.state.quizSet.pgd === true &&
      this.state.quizSet.pgdInfo.acknowledged !== true
    ) {
      await RemoteDataController.downloadLatestSinglePGDAcknowledgement(
        this.props.screenProps.user.plusTrusts,
        this.props.screenProps.user.uid,
        this.state.quizSet.pgdInfo,
      );
      await this.props.screenProps.updatePGDAcknowledgements();
      this.props.screenProps.updateQuizSets(false);
    }
  }

  backButtonTapped() {
    var comingFromQuiz = this.props.navigation.getParam(
      'comingFromQuiz',
      false,
    );

    if (comingFromQuiz) {
      this.props.navigation.pop(2);
    } else {
      this.props.navigation.goBack();
    }
  }

  showInDepthResults() {
    this.setState({showInDepthResults: true});
  }

  goToSingleResult(result, tappedQuestion) {
    var questionsWithResults = [];
    var unansweredQuestions = [];

    var selectedIndex = 0;

    for (
      let questionIndex = 0;
      questionIndex < this.state.questions.length;
      questionIndex++
    ) {
      var question = this.state.questions[questionIndex];

      var result = null;

      for (
        let resultIndex = 0;
        resultIndex < this.state.results.length;
        resultIndex++
      ) {
        var aResult = this.state.results[resultIndex];
        var resultQuestionName = aResult.packName + '-' + aResult.questionID;

        if (resultQuestionName === question.Name) {
          result = aResult;
          break;
        }
      }

      if (result) {
        questionsWithResults.push(question);

        if (_.isEqual(question, tappedQuestion)) {
          selectedIndex = questionIndex;
        }
      } else {
        unansweredQuestions.push(question.Name);
      }
    }

    this.props.navigation.navigate(
      'QuizSingleResult',
      {
        quiz: this.state.quiz,
        quizSet: this.state.quizSet,
        questions: this.state.questions,
        unansweredQuestions: unansweredQuestions,
        results: this.state.results,
        initialQuestionNumber: selectedIndex + 1,
      },
      this.state,
    );
  }

  async viewCertificate() {
    try {
      this.setState({loadingCertificate: true});

      if (this.props.screenProps.user != null) {
        try {
          let comingFrom = 'Results';
          var comingFromQuiz = this.props.navigation.getParam(
            'comingFromQuiz',
            false,
          );
          if (comingFromQuiz) {
            comingFrom = 'Quiz';
          }

          firebase
            .analytics()
            .logEvent('certificate_viewed', {from: comingFrom});
        } catch (error) {
          console.log(error);
        }

        let certificateFileName =
          'certificate_' + moment().format('DD-MMM-YYYY_hh-mm-ss') + '.pdf';

        var response = await RemoteDataController.createCertificate(
          certificateFileName,
          this.state.quizSet,
          this.state.quiz,
          this.props.screenProps.user,
          this.state.results,
        );
        console.log('certificate created');
        console.log(response);

        if (Platform.OS === 'web') {
          var responseBlob = await response.blob();

          var fileBlob = new Blob([responseBlob], {type: 'application/pdf'});

          // if IE or Edge
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(fileBlob, 'certificate.pdf');
          } else {
            // var isFirefox = typeof InstallTrigger !== 'undefined';

            // if (isFirefox) {

            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(fileBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = 'certificate.pdf';
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);

            // } else {

            //   // Other browsers
            //   var fileURL = URL.createObjectURL(fileBlob);
            //   window.open(fileURL);
            // }
          }
        } else {
          var pdfFilePath =
            'file://' +
            DataController.getCertificateFilePath(certificateFileName);

          this.props.navigation.navigate(
            'PDFViewer',
            {
              pdfFilePath: pdfFilePath,
            },
            this.state,
          );
        }
      }

      this.setState({loadingCertificate: false});
    } catch (error) {
      Alert.alert(error);
      this.setState({loadingCertificate: false});
    }
  }

  async addToParafolio(activityDescription,timelineReference) {
    try {
      let title = '';
      let activityDate = 0;

      if (this.state.quizSet && this.state.quizSet.title) {
        title = this.state.quizSet.title;
      }

      if (this.state.quiz && this.state.quiz.time) {
        let timestamp = new Date(this.state.quiz.time);
        activityDate = timestamp.getTime();
      }

      PlatformController.openURL(
        `https://parafolio.co.uk/?addreflection=true&title=${title}&activitydate=${activityDate}&activitydescription=${activityDescription}&timelinereference=${timelineReference}&type='quiz'`,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async pgdDialogueButtonTapped() {
    if (this.props.screenProps.trustInfo.authorisationType === 'Manual') {
      await this.acknowledgePGD();
    }

    this.setState({shouldShowPGDDialogue: false});
  }

  async acknowledgePGD() {
    if (this.state.sendingPGDAuthorisation) {
      return;
    }

    if (
      this.props.screenProps.trustInfo.authorisationType === 'Manual' &&
      this.state.shouldShowPGDDialogue === false
    ) {
      this.setState({shouldShowPGDDialogue: true});
      return;
    }

    try {
      this.setState({sendingPGDAuthorisation: true});

      if (this.props.screenProps.user != null) {
        await RemoteDataController.acknowledgePGD(
          this.props.screenProps.user.plusTrusts,
          this.props.screenProps.user.uid,
          this.state.quizSet.pgdInfo,
        );
        await this.props.screenProps.updatePGDAcknowledgements();
        this.props.screenProps.updateQuizSets(false);
      }

      this.setState({
        sendingPGDAuthorisation: false,
        shouldShowPGDDialogue: false,
      });
    } catch (error) {
      Alert.alert(error);
      this.setState({sendingPGDAuthorisation: false});
    }
  }

  takeQuiz() {
    this.props.navigation.navigate(
      'QuizOverview',
      {
        quizSet: this.state.quizSet,
        from: 'Quiz Results',
      },
      this.state,
    );
  }

  retakeQuiz(quizMode) {
    var test = '';

    var questionsForQuizSet = this.state.quizSet.questions.slice();

    var isSequential = this.isQuizSequential();

    var latestResultsForQuizSets =
      this.props.screenProps.latestResultsForQuizSets[this.state.quizSet.name];
    var correctlyAnsweredQuestions = [];
    var incorrectlyAnsweredQuestions = [];
    var mandatoryQuestions = [];

    for (
      let questionIndex = 0;
      questionIndex < questionsForQuizSet.length;
      questionIndex++
    ) {
      let question = questionsForQuizSet[questionIndex];
      if (question.Mandatory) {
        mandatoryQuestions.push(question.Name);
      }
    }

    if (latestResultsForQuizSets == null) {
      latestResultsForQuizSets = [];
    }

    for (
      let resultIndex = 0;
      resultIndex < latestResultsForQuizSets.length;
      resultIndex++
    ) {
      var result = latestResultsForQuizSets[resultIndex];
      var questionName = result.packName + '-' + result.questionID;

      if (result.correct) {
        correctlyAnsweredQuestions.push(questionName);
      } else {
        incorrectlyAnsweredQuestions.push(questionName);
      }
    }

    if (isSequential) {
      questionsForQuizSet = _.sortBy(questionsForQuizSet, ['QuestionID']);
    } else {
      // sort so questions are ordered: mandatory questions, incorrect questions, unanswered questions, correctly answered questions (but questions in each of those categories are still shuffled from earlier)
      questionsForQuizSet.sort(function (question1, question2) {
        var question1Value = 2;
        var question2Value = 2;

        if (mandatoryQuestions.includes(question1.Name)) {
          question1Value = 0;
        } else if (incorrectlyAnsweredQuestions.includes(question1.Name)) {
          question1Value = 1;
        } else if (correctlyAnsweredQuestions.includes(question1.Name)) {
          question1Value = 3;
        }

        if (mandatoryQuestions.includes(question2.Name)) {
          question2Value = 0;
        } else if (incorrectlyAnsweredQuestions.includes(question2.Name)) {
          question2Value = 1;
        } else if (correctlyAnsweredQuestions.includes(question2.Name)) {
          question2Value = 3;
        }

        return question1Value - question2Value;
      });
    }

    var questionsForQuiz = questionsForQuizSet;

    if (quizMode === '10 Questions') {
      questionsForQuiz = questionsForQuizSet.slice(0, 10);
    } else if (quizMode === 'PGD') {
      questionsForQuiz = questionsForQuizSet.slice(0, 5);
    } else if (quizMode === 'Incorrect Questions') {
      questionsForQuiz = [];

      for (
        let quizSetQuestionIndex = 0;
        quizSetQuestionIndex < questionsForQuizSet.length;
        quizSetQuestionIndex++
      ) {
        var quizSetQuestion = questionsForQuizSet[quizSetQuestionIndex];

        for (
          var resultIndex = 0, resultsLength = this.state.results.length;
          resultIndex < resultsLength;
          resultIndex++
        ) {
          var result = this.state.results[resultIndex];
          var resultName = result.packName + '-' + result.questionID;

          if (resultName === quizSetQuestion.Name && result.correct === false) {
            questionsForQuiz.push(quizSetQuestion);
          }
        }
      }
    }

    if (this.state.isSequential === false) {
      // shuffle picked questions so mandatory questions aren't always first
      questionsForQuiz = _.shuffle(questionsForQuiz);
    }

    var now = new Date();
    var nowTimestamp = now.getTime();

    var questionIDsForQuiz = questionsForQuiz.map(function (question) {
      return question.PackName + '-' + question.QuestionID;
    });

    var quiz = {
      name: this.state.quizSet.name + '-' + nowTimestamp,
      time: nowTimestamp,
      quizSet: this.state.quizSet.name,
      quizMode: quizMode,
      questions: questionIDsForQuiz,
    };

    // go back before navigating so don't navigate to existing quiz screen
    this.backButtonTapped();

    this.props.navigation.navigate(
      'Quiz',
      {
        quiz: quiz,
        quizSet: this.state.quizSet,
        questions: questionsForQuiz,
        from: 'Retake quiz',
      },
      this.state,
    );
  }

  retakeIncorrectQuestions() {
    var questionsForQuiz = [];
    var currentQuestion = null;
    var currentQuestionNumber = 1;
    var questionNumbersAnswered = [];
    var questionNumbersViewed = [];
    var correctCount = 0;
    var incorrectCount = 0;

    for (
      var quizQuestionIndex = 0;
      quizQuestionIndex < this.state.quiz.questions.length;
      quizQuestionIndex++
    ) {
      var quizQuestionName = this.state.quiz.questions[quizQuestionIndex];
      var questionForQuiz = null;

      // add full question to quiz
      for (
        var questionIndex = 0,
          questionsLength = this.props.screenProps.questions.length;
        questionIndex < questionsLength;
        questionIndex++
      ) {
        var question = this.props.screenProps.questions[questionIndex];

        if (question.Name === quizQuestionName) {
          questionsForQuiz.push(question);
          questionForQuiz = question;
        }
      }

      var resultFound = false;

      for (
        let resultIndex = 0;
        resultIndex < this.state.results.length;
        resultIndex++
      ) {
        var result = this.state.results[resultIndex];
        var resultQuestionName = result.packName + '-' + result.questionID;

        if (
          result.quizName === this.state.quiz.name &&
          resultQuestionName === quizQuestionName
        ) {
          if (result.correct) {
            correctCount++;

            resultFound = true;

            questionNumbersAnswered.push(quizQuestionIndex + 1);
            questionNumbersViewed.push(quizQuestionIndex + 1);
          } else {
            incorrectCount++;
          }
        }
      }

      if (resultFound === false && currentQuestion == null) {
        currentQuestionNumber = quizQuestionIndex + 1;
        currentQuestion = questionForQuiz;
      }
    }

    let quizParams = {
      quiz: this.state.quiz,
      quizSet: this.state.quizSet,
      questions: questionsForQuiz,
      currentQuestion: currentQuestion,
      currentQuestionNumber: currentQuestionNumber,
      questionNumbersAnswered: questionNumbersAnswered,
      questionNumbersViewed: questionNumbersViewed,
      correctCount: correctCount,
      incorrectCount: incorrectCount,
      comingFromOverviewScreen: false,
      retakingIncorrectQuestions: true,
      from: 'Retake incorrect questions',
    };

    // try replacing existing Quiz screen in stack - else navigate to it if doesn't exist
    NavigationController.replaceScreen(this.props.navigation, quizParams);

    this.props.navigation.navigate('Quiz', quizParams, this.state);
  }

  isQuizSequential() {
    var isSequential = false;

    if (this.state.quizSet.type === 'topic') {
      var topic = _.find(this.props.screenProps.topics, {
        PackName: this.state.quizSet.topicName,
      });

      if (topic != null && topic.Sequential === true) {
        isSequential = true;
      }
    }

    if (
      this.state.quizSet.type === 'standby' ||
      this.state.quizSet.type === 'caseStudy'
    ) {
      isSequential = true;
    }

    return isSequential;
  }

  render() {
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var questionsLength = this.state.quiz.questions.length;
    var percentageText = '0%';

    let pgdQuizType = 'PGD';
    if (this.state.quizSet && this.state.quizSet.pma === true) {
      pgdQuizType = 'PMA';
    }

    var resultLayouts = [];

    for (
      var resultIndex = 0, resultsLength = this.state.results.length;
      resultIndex < resultsLength;
      resultIndex++
    ) {
      var result = this.state.results[resultIndex];

      if (result.correct) {
        correctCount++;
      } else {
        incorrectCount++;
      }
    }

    var quizzesForQuizSet = [];

    for (
      let quizIndex = 0;
      quizIndex < this.props.screenProps.quizzes.length;
      quizIndex++
    ) {
      let quiz = this.props.screenProps.quizzes[quizIndex];

      if (quiz.quizSet === this.state.quizSet.name) {
        quizzesForQuizSet.push(quiz);
      }
    }

    // if (this.state.quiz.quizMode === '5 Minutes') {
    // questionsLength = correctCount + incorrectCount + unansweredQuestionsCount;
    // }

    unansweredCount = questionsLength - correctCount - incorrectCount;

    if (
      questionsLength > 0 &&
      correctCount > 0 &&
      correctCount + incorrectCount > 0
    ) {
      percentageText =
        Math.floor((correctCount / (correctCount + incorrectCount)) * 100) +
        '%';
    }

    var questionsText = 'Questions';
    if (questionsLength === 1) {
      questionsText = 'Question';
    }

    var unansweredWordText = 'questions';
    if (unansweredCount === 1) {
      unansweredWordText = 'question';
    }

    var quizModeText = 'Short Quiz';

    if (this.state.quiz.quizMode === 'All Questions') {
      quizModeText = 'All Questions';
    } else if (this.state.quiz.quizMode === '5 Minutes') {
      quizModeText = '5 Minute Quiz';
    } else if (this.state.quiz.quizMode === 'Incorrect Questions') {
      quizModeText = 'Incorrect Questions';
    } else if (this.state.quiz.quizMode === 'PGD') {
      quizModeText = `${pgdQuizType} Quiz`;
    }

    var viewResultsInDepthLayout = null;

    if (this.state.showInDepthResults === false) {
      viewResultsInDepthLayout = (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            backgroundColor: colors.GreyA00,
            borderTopWidth: 1,
            borderColor: colors.Grey100,
          }}>
          <TouchableOpacity
            style={{flexDirection: 'row', alignItems: 'center'}}
            onPress={this.showInDepthResults.bind(this)}>
            <DynamicText
              style={{
                marginRight: 0,
                marginVertical: 14,
                fontSize: 17,
                fontWeight: '600',
                letterSpacing: -0.39,
                color: colors.ButtonBlue,
                textAlign: 'right',
              }}>
              View results in-depth
            </DynamicText>
            <Image
              style={{width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </TouchableOpacity>
        </View>
      );
    }

    for (
      let questionIndex = 0;
      questionIndex < this.state.questions.length;
      questionIndex++
    ) {
      var question = this.state.questions[questionIndex];

      var result = null;

      for (
        let resultIndex = 0;
        resultIndex < this.state.results.length;
        resultIndex++
      ) {
        var aResult = this.state.results[resultIndex];
        var resultQuestionName = aResult.packName + '-' + aResult.questionID;

        if (resultQuestionName === question.Name) {
          result = aResult;

          break;
        }
      }

      var answerStatusLayout = null;

      if (result != null) {
        if (result.correct) {
          answerStatusLayout = (
            <Image
              style={{width: 30, height: 30}}
              source={require('../assets/Answer_Tick_Green.png')}
            />
          );
        } else {
          answerStatusLayout = (
            <Image
              style={{width: 30, height: 30}}
              source={require('../assets/Answer_Cross_Red.png')}
            />
          );
        }
      } else {
        answerStatusLayout = (
          <DynamicText style={{color: colors.Grey700}}>Unanswered</DynamicText>
        );
      }

      var shouldShowQuestion = false;

      if (result) {
        shouldShowQuestion = true;
      }

      if (shouldShowQuestion) {
        var numberOfLinesForQuestion = 2;

        if (Platform.OS === 'web') {
          numberOfLinesForQuestion = 1;
        }

        var resultAnswerText = result.answer;
        resultAnswerText = resultAnswerText.replace(/\|/g, ', ');

        var resultID =
          result.packName + '-' + result.questionID + '-' + result.quizName;

        resultLayouts.push(
          <TouchableOpacity
            key={resultID}
            activeOpacity={0.3}
            onPress={this.goToSingleResult.bind(this, result, question)}>
            <View
              key={question.Name}
              style={{
                paddingHorizontal: 20,
                paddingVertical: 15,
                borderBottomWidth: 1,
                borderBottomColor: colors.Grey200,
                borderLeftWidth: 4,
                borderLeftColor: DataController.getPrimaryColorForSection(
                  question.Section,
                ),
              }}>
              <View style={{flexDirection: 'row'}}>
                <DynamicText
                  style={{
                    width: 100,
                    fontSize: 15,
                    letterSpacing: -0.24,
                    color: colors.Grey800,
                  }}>
                  Q. {questionIndex + 1}
                </DynamicText>
                <DynamicText
                  style={{
                    flex: 1,
                    fontSize: 15,
                    fontWeight: '500',
                    letterSpacing: -0.24,
                    color: colors.Grey900,
                  }}
                  numberOfLines={numberOfLinesForQuestion}>
                  {question.Question}
                </DynamicText>
              </View>

              <View style={{flexDirection: 'row', marginTop: 10}}>
                <DynamicText
                  style={{
                    width: 100,
                    fontSize: 15,
                    letterSpacing: -0.24,
                    color: colors.Grey800,
                  }}>
                  Answer
                </DynamicText>
                <DynamicText
                  style={{
                    flex: 1,
                    fontSize: 15,
                    fontWeight: '500',
                    letterSpacing: -0.24,
                    color: colors.Grey900,
                  }}
                  numberOfLines={numberOfLinesForQuestion}>
                  {resultAnswerText}
                </DynamicText>
                {answerStatusLayout}
              </View>
            </View>
          </TouchableOpacity>,
        );
      }
    }

    var fullResultsLayout = null;

    // if (this.state.showInDepthResults) {
    fullResultsLayout = (
      <View style={{marginBottom: 14, backgroundColor: colors.white}}>
        <View
          style={{
            paddingHorizontal: 20,
            paddingVertical: 10,
            borderBottomWidth: 1,
            borderBottomColor: colors.Grey200,
          }}>
          <DynamicText
            style={{fontSize: 17, fontWeight: '800', color: colors.darkGrey}}>
            Your Answers
          </DynamicText>
          <DynamicText
            style={{marginTop: 6, fontSize: 13, color: colors.darkGrey}}>
            Tap on a question to view it in full
          </DynamicText>
        </View>

        {resultLayouts}
      </View>
    );
    // }

    const keys = ['unanswered', 'incorrect', 'correct'];
    const values = [unansweredCount, incorrectCount, correctCount];
    const pieChartColors = [colors.Grey400, colors.Red500, colors.Green500];

    var typeLayout = null;
    var typeTitle = null;
    var typeTitleCapitalised = '';

    if (this.state.quizSet.type === 'topic') {
      typeTitle = 'topic';

      let trust = null;
      if (this.state.quizSet.trust != null && this.state.quizSet.trust !== '') {
        trust = this.state.quizSet.trust;
      }

      if (this.state.quizSet.pgd === true && trust != null) {
        typeLayout = (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View
              style={{
                alignSelf: 'flex-start',
                justifyContent: 'center',
                paddingHorizontal: 4,
                height: 16,
                borderRadius: 2,
                backgroundColor: DataController.getColorForTrust(trust),
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '500',
                  letterSpacing: -0.1,
                  color: colors.white,
                }}>
                {DataController.getTrustDisplayName(trust)} {pgdQuizType}
              </Text>
            </View>
            <View
              style={{
                marginHorizontal: 6,
                width: 2,
                height: 2,
                backgroundColor: colors.Grey700,
                borderRadius: 1,
              }}
            />
            <Image
              style={{width: 16, height: 16}}
              source={require('../assets/Glyph_Small_PGD.png')}
            />
          </View>
        );
      } else if (trust != null) {
        typeLayout = (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View
              style={{
                alignSelf: 'flex-start',
                justifyContent: 'center',
                paddingHorizontal: 4,
                height: 16,
                borderRadius: 2,
                backgroundColor: DataController.getColorForTrust(trust),
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '500',
                  letterSpacing: -0.1,
                  color: colors.white,
                }}>
                {DataController.getTrustDisplayName(trust)}
              </Text>
            </View>
          </View>
        );
      } else {
        typeLayout = (
          <Image
            style={{width: 38, height: 14}}
            source={require('../assets/Logo_JRCALC_Small.png')}
          />
        );
      }
    }

    if (this.state.quizSet.type === 'standby') {
      typeTitle = 'Standyby CPD';
      typeLayout = (
        <Image
          style={{width: 85, height: 13}}
          source={require('../assets/Logo_Standby_Small.png')}
        />
      );
    }

    if (this.state.quizSet.type === 'caseStudy') {
      typeTitle = 'case study';
      typeLayout = (
        <DynamicText
          style={{fontSize: 12, fontWeight: '500', color: colors.SectDefault}}>
          Case Study
        </DynamicText>
      );
    }

    if (this.state.quizSet.type === 'section') {
      typeTitle = 'section';
    }

    if (this.state.quizSet.type === 'guideline') {
      typeTitle = 'guideline';
    }

    if (typeTitle !== '') {
      typeTitleCapitalised =
        typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1);
    }

    var dateString = moment(this.state.quiz.time).format('DD MMM YYYY');
    var timeString = moment(this.state.quiz.time).format('HH:mm');

    var loadingCertificateLayout = null;
    if (this.state.loadingCertificate) {
      loadingCertificateLayout = (
        <ActivityIndicator
          style={{marginLeft: 10}}
          color={colors.white}
          animating={true}
          size={'small'}></ActivityIndicator>
      );
    }

    var certificateButtonLayout = (
      <TouchableOpacity
        activeOpacity={0.7}
        style={{flex: 1}}
        onPress={this.viewCertificate.bind(this)}>
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            marginTop: 15,
            paddingVertical: 16,
            backgroundColor: colors.ButtonBlue,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 6,
          }}>
          <DynamicText
            style={{
              fontSize: 16,
              fontWeight: '700',
              letterSpacing: -0.39,
              color: colors.white,
            }}>
            View certificate
          </DynamicText>
          {loadingCertificateLayout}
        </View>
      </TouchableOpacity>
    );

    var addToParafolioLayout = null;

    if (this.props.screenProps.shouldShowParaFolioIntegrations) {
      const activityDescription =`Completed ParaPass “${this.state.quizSet.title}” quiz and scored ${correctCount}/${questionsLength} on ${dateString}`
      let timelineReference = `${this.state.quizSet.name}-${this.state.quizSet.date}`;

      addToParafolioLayout = (
        <TouchableOpacity
          activeOpacity={0.7}
          style={{flex: 1}}
          onPress={this.addToParafolio.bind(this, activityDescription, timelineReference)}>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              marginTop: 15,
              paddingVertical: 16,
              backgroundColor: colors.ButtonBlue,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 6,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
            }}>
            <DynamicText
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.white,
              }}>
              Add to ParaFolio
            </DynamicText>
          </View>
        </TouchableOpacity>
      );
    }

    var certificateLayout = (
      <View>
        <DynamicText
          style={{marginTop: 10, fontSize: 16, color: colors.Grey900}}>
          You have successfully completed this quiz and can now download your
          course certificate (please make sure that you have filled out your
          name in your account).
        </DynamicText>
        {certificateButtonLayout}
        {addToParafolioLayout}
      </View>
    );

    var pgdAuthoriseButtonLayout = null;

    if (this.state.quizSet.pgd === true) {
      var sendingPGDAuthorisationLayout = null;
      if (this.state.sendingPGDAuthorisation) {
        sendingPGDAuthorisationLayout = (
          <ActivityIndicator
            style={{marginLeft: 10}}
            color={colors.white}
            animating={true}
            size={'small'}></ActivityIndicator>
        );
      }

      var disclaimerTextLayout = null;

      if (
        this.props.screenProps.user != null &&
        this.props.screenProps.user.plusTrusts != null
      ) {
        if (this.props.screenProps.user.plusTrusts.includes('SECAMB')) {
          disclaimerTextLayout = (
            <DynamicText
              style={{marginTop: 10, fontSize: 16, color: colors.Grey900}}>
              This certificate is intended for use for your personal CPD
              portfolio only. It does not constitute an official document for{' '}
              {pgdQuizType} authorisation.
            </DynamicText>
          );
        }
      }

      var authoriseText = `You are also able to authorise the ${pgdQuizType}. Authorising the ${pgdQuizType} in ParaPass will mean the ${pgdQuizType} will appear as authorised on your JRCALC Plus app.`;

      if (this.state.quizSet.pgdInfo.acknowledged) {
        authoriseText = `This ${pgdQuizType} is now authorised in both your ParaPass app and JRCALC PLUS app.`;
      }

      var authoriseTextLayout = (
        <DynamicText
          style={{marginTop: 10, fontSize: 16, color: colors.Grey900}}>
          {authoriseText}
        </DynamicText>
      );

      if (
        correctCount === questionsLength &&
        this.state.quizCanBeUsedToAuthorise
      ) {
        certificateLayout = (
          <View>
            <DynamicText
              style={{marginTop: 10, fontSize: 16, color: colors.Grey900}}>
              You have successfully completed this {pgdQuizType} quiz and can
              now download your certificate (please make sure that you have
              filled out your name in your account).
            </DynamicText>
            {disclaimerTextLayout}
            {authoriseTextLayout}
            {certificateButtonLayout}
            {addToParafolioLayout}
          </View>
        );
      } else {
        if (this.state.quizSet.pgdInfo.acknowledged) {
          certificateLayout = (
            <View>
              <DynamicText
                style={{marginTop: 10, fontSize: 16, color: colors.Grey900}}>
                This {pgdQuizType} has been authorised in another quiz.
              </DynamicText>
            </View>
          );
        } else if (this.state.quizCanBeUsedToAuthorise === false) {
          certificateLayout = (
            <View>
              <DynamicText
                style={{marginTop: 10, fontSize: 16, color: colors.Grey900}}>
                This quiz result is too old to be used to authorise. Please take
                again and successfully answer all the questions in this quiz to
                authorise the {pgdQuizType}.
              </DynamicText>
            </View>
          );
        } else {
          certificateLayout = (
            <View>
              <DynamicText
                style={{marginTop: 10, fontSize: 16, color: colors.Grey900}}>
                You need to successfully answer all the questions in this quiz
                to authorise the {pgdQuizType}.
              </DynamicText>
            </View>
          );
        }
      }

      if (this.state.quizSet.pgdInfo.acknowledged) {
        pgdAuthoriseButtonLayout = (
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              marginTop: 15,
              paddingVertical: 8,
              backgroundColor: '#F8F9F9',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 6,
              borderWidth: 1,
              borderColor: colors.Grey500,
            }}>
            <Image
              style={{
                width: 30,
                height: 30,
                marginRight: 6,
                tintColor: colors.Grey700,
              }}
              source={require('../assets/Answer_Tick_Correct.png')}
            />
            <DynamicText
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.Grey700,
              }}>
              Authorised
            </DynamicText>
          </View>
        );
      } else if (
        correctCount === questionsLength &&
        this.state.quizCanBeUsedToAuthorise
      ) {
        pgdAuthoriseButtonLayout = (
          <TouchableOpacity
            activeOpacity={0.7}
            style={{flex: 1}}
            onPress={this.acknowledgePGD.bind(this)}>
            <View
              style={{
                flexDirection: 'row',
                flex: 1,
                marginTop: 15,
                paddingVertical: 16,
                backgroundColor: colors.ButtonBlue,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 6,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
              }}>
              <DynamicText
                style={{
                  fontSize: 16,
                  fontWeight: '700',
                  letterSpacing: -0.39,
                  color: colors.white,
                }}>
                Authorise {pgdQuizType}
              </DynamicText>
              {sendingPGDAuthorisationLayout}
            </View>
          </TouchableOpacity>
        );
      }
    }

    var retakeIncorrectQuestionsLayout = (
      <TouchableOpacity
        activeOpacity={0.7}
        style={{flex: 1}}
        onPress={this.retakeIncorrectQuestions.bind(this)}>
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            marginTop: 15,
            paddingVertical: 15,
            backgroundColor: colors.white,
            borderWidth: 1,
            borderColor: colors.ButtonBlue,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 8,
          }}>
          <DynamicText
            style={{
              fontSize: 16,
              fontWeight: '700',
              letterSpacing: -0.39,
              color: colors.ButtonBlue,
            }}>
            Retry incorrect questions
          </DynamicText>
        </View>
      </TouchableOpacity>
    );

    if (
      incorrectCount === 0 ||
      this.isQuizSequential() ||
      this.state.quizSet.pgd === true ||
      this.state.quiz.quizMode === '5 Minute Quiz'
    ) {
      retakeIncorrectQuestionsLayout = null;
    }

    var quizSetResults =
      this.props.screenProps.latestResultsForQuizSets[this.state.quizSet.name];

    if (quizSetResults == null) {
      quizSetResults = [];
    }

    var pgdDialogueLayout = null;

    var pgdDialogueMessage = `This ${pgdQuizType} will now appear as authorised in both your ParaPass app and JRCALC PLUS app. Continue to see your results for this quiz.`;
    var pgdDialogueButtonText = 'Continue';
    var authorisationText = '';

    if (this.props.screenProps.trustInfo.authorisationType === 'Manual') {
      pgdDialogueMessage = `You are now able to authorise this ${pgdQuizType} through the button below. Please note that authorising the ${pgdQuizType} in ParaPass will mean that the ${pgdQuizType} will appear as authorised in your JRCALC PLUS app.`;
      pgdDialogueButtonText = `Acknowledge ${pgdQuizType}`;
      authorisationText = this.props.screenProps.trustInfo.authorisationText;
      if (
        this.state.quizSet.pma === true &&
        this.props.screenProps.trustInfo.pmaAuthorisationText
      ) {
        authorisationText =
          this.props.screenProps.trustInfo.pmaAuthorisationText;
      }
    }

    if (this.state.shouldShowPGDDialogue) {
      pgdDialogueLayout = (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.6)',
          }}>
          <View
            style={{
              marginHorizontal: 30,
              paddingHorizontal: 10,
              paddingVertical: 16,
              maxWidth: 354,
              backgroundColor: colors.white,
              borderRadius: 6,
            }}>
            <DynamicText
              style={{
                fontSize: 20,
                fontWeight: '600',
                textAlign: 'center',
                letterSpacing: -0.4,
                color: colors.Grey900,
              }}>
              You have successfully passed this {pgdQuizType} quiz.
            </DynamicText>
            <DynamicText
              style={{
                marginTop: 8,
                fontSize: 16,
                textAlign: 'center',
                letterSpacing: -0.24,
                color: colors.Grey900,
              }}>
              {pgdDialogueMessage}
            </DynamicText>

            <TouchableOpacity
              activeOpacity={0.7}
              onPress={this.pgdDialogueButtonTapped.bind(this)}>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 15,
                  paddingVertical: 16,
                  backgroundColor: colors.ButtonBlue,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 6,
                  shadowOffset: {width: 0, height: 1},
                  shadowRadius: 1,
                  shadowColor: 'black',
                  shadowOpacity: 0.2,
                }}>
                <DynamicText
                  style={{
                    fontSize: 16,
                    fontWeight: '700',
                    letterSpacing: -0.39,
                    color: colors.white,
                  }}>
                  {pgdDialogueButtonText}
                </DynamicText>
                {sendingPGDAuthorisationLayout}
              </View>
            </TouchableOpacity>
          </View>
        </View>
      );
    }

    const promoData = this.props.screenProps?.promoData;
    const showPromotion =
      this.props.screenProps.isPromotionAvailable() && promoData?.promoCode;

    const {title, subtitle} = getStringsForPromoCard(
      this.props.screenProps.promoData,
      this.props.screenProps.promoCollected,
    );
    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView styles={{flex: 1}}>
          <View style={{marginBottom: 14, backgroundColor: colors.white}}>
            {showPromotion && (
              <PromoBanner
                title={title}
                subtitle={subtitle}
                promoCode={this.props.screenProps.promoData.promoCode}
                onPress={() =>
                  this.props.screenProps.handlePromoModalToShow('qrCode')
                }
                isButtonDisabled={this.props.screenProps.promoCollected}
                expiryDate={this.props.screenProps.promotions.FinalDate}
              />
            )}
            <View style={{paddingHorizontal: 20, paddingVertical: 20}}>
              {typeLayout}

              <DynamicText
                style={{
                  marginTop: 10,
                  fontSize: 22,
                  fontWeight: '700',
                  color: colors.Grey900,
                }}>
                {this.state.quizSet.title}
              </DynamicText>
              <DynamicText
                style={{
                  marginTop: 10,
                  fontSize: 15,
                  fontWeight: '600',
                  letterSpacing: -0.3,
                  color: colors.Grey900,
                }}>
                {quizModeText} • Completed {dateString} at {timeString}
              </DynamicText>

              {pgdAuthoriseButtonLayout}

              {certificateLayout}
            </View>
          </View>

          <View style={{marginBottom: 14, backgroundColor: colors.white}}>
            <View style={{paddingHorizontal: 20, paddingVertical: 20}}>
              <DynamicText
                style={{
                  fontSize: 17,
                  color: colors.darkGrey,
                  fontWeight: '800',
                }}>
                Quiz results
              </DynamicText>

              <ProgressView
                style={{
                  flex: 1,
                  marginTop: 16,
                  height: 4,
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
                correctAnswers={correctCount}
                incorrectAnswers={incorrectCount}
                unansweredQuestions={unansweredCount}
              />

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 15,
                }}>
                <View>
                  <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                    <DynamicText
                      style={{
                        fontSize: 34,
                        fontWeight: '700',
                        color: colors.Green500,
                      }}>
                      {correctCount}
                    </DynamicText>
                    <DynamicText
                      style={{
                        marginBottom: 8,
                        fontSize: 11,
                        fontWeight: '600',
                        color: colors.Grey800,
                      }}>
                      {' '}
                      / {questionsLength}
                    </DynamicText>
                  </View>
                  <DynamicText
                    style={{
                      fontSize: 11,
                      fontWeight: '600',
                      color: colors.Grey900,
                    }}>
                    Correct
                  </DynamicText>
                </View>

                <View>
                  <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                    <DynamicText
                      style={{
                        fontSize: 34,
                        fontWeight: '700',
                        color: colors.Red500,
                      }}>
                      {incorrectCount}
                    </DynamicText>
                  </View>
                  <DynamicText
                    style={{
                      fontSize: 11,
                      fontWeight: '600',
                      color: colors.Grey900,
                    }}>
                    Incorrect
                  </DynamicText>
                </View>

                <View>
                  <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                    <DynamicText
                      style={{
                        fontSize: 34,
                        fontWeight: '700',
                        color: colors.Grey700,
                      }}>
                      {unansweredCount}
                    </DynamicText>
                  </View>
                  <DynamicText
                    style={{
                      fontSize: 11,
                      fontWeight: '600',
                      color: colors.Grey900,
                    }}>
                    Unanswered
                  </DynamicText>
                </View>
              </View>

              {retakeIncorrectQuestionsLayout}
            </View>
          </View>

          <View style={{marginBottom: 14, backgroundColor: colors.white}}>
            <View style={{paddingHorizontal: 20, paddingVertical: 20}}>
              <DynamicText
                style={{
                  fontSize: 17,
                  color: colors.darkGrey,
                  fontWeight: '800',
                }}>
                Overall
              </DynamicText>
              <DynamicText
                style={{marginTop: 8, fontSize: 16, color: colors.Grey900}}>
                So far you've answered{' '}
                <DynamicText style={{fontSize: 16, fontWeight: '600'}}>
                  {quizSetResults.length}
                </DynamicText>{' '}
                {quizSetResults.length === 1 ? 'question' : 'questions'} out of
                a possible{' '}
                <DynamicText style={{fontSize: 16, fontWeight: '600'}}>
                  {this.state.quizSet.questions.length}
                </DynamicText>
                , and taken{' '}
                <DynamicText style={{fontSize: 16, fontWeight: '600'}}>
                  {quizzesForQuizSet.length}
                </DynamicText>{' '}
                {quizzesForQuizSet.length === 1
                  ? 'quiz attempt'
                  : 'quiz attempts'}{' '}
                on this {typeTitle}.
              </DynamicText>

              <TouchableOpacity
                activeOpacity={0.7}
                style={{flex: 1}}
                onPress={this.takeQuiz.bind(this)}>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 1,
                    marginTop: 15,
                    paddingVertical: 16,
                    backgroundColor: colors.white,
                    borderWidth: 1,
                    borderColor: colors.ButtonBlue,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 8,
                  }}>
                  <DynamicText
                    style={{
                      fontSize: 16,
                      fontWeight: '700',
                      letterSpacing: -0.39,
                      color: colors.ButtonBlue,
                    }}>
                    Take quiz
                  </DynamicText>
                </View>
              </TouchableOpacity>
            </View>
            {viewResultsInDepthLayout}
          </View>

          {fullResultsLayout}
        </ScrollView>

        {/* {pgdDialogueLayout} */}

        <Modal
          visible={this.state.shouldShowPGDDialogue}
          onBackButtonPress={() =>
            this.setState({shouldShowPGDDialogue: false})
          }
          presentationStyle="overFullScreen"
          animationType="slide"
          style={{flex: 1}}>
          <View style={{flex: 1, backgroundColor: colors.Grey300}}>
            <View
              style={{
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                height: PlatformController.getTopInset() + 44,
                backgroundColor: colors.NavBar,
              }}>
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  height: 44,
                }}
                onPress={() => this.setState({shouldShowPGDDialogue: false})}>
                <Text
                  style={{
                    color: colors.white,
                    fontSize: 16,
                    paddingHorizontal: 16,
                  }}>
                  Close
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                margin: 10,
                backgroundColor: colors.white,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
              }}>
              <ScrollView
                style={{flex: 1}}
                contentContainerStyle={{
                  paddingHorizontal: 10,
                  paddingVertical: 16,
                }}>
                <DynamicText
                  style={{
                    fontSize: 20,
                    fontWeight: '600',
                    textAlign: 'center',
                    letterSpacing: -0.4,
                    color: colors.Grey900,
                  }}>
                  You have successfully passed this {pgdQuizType} quiz.
                </DynamicText>
                <DynamicText
                  style={{
                    marginTop: 8,
                    fontSize: 16,
                    textAlign: 'center',
                    letterSpacing: -0.24,
                    color: colors.Grey900,
                  }}>
                  {pgdDialogueMessage}
                </DynamicText>

                {authorisationText !== '' ? (
                  <View
                    style={{
                      marginTop: 16,
                      padding: 9,
                      backgroundColor: colors.DisclaimerRed,
                      borderWidth: 1,
                      borderRadius: 3,
                      borderColor: 'red',
                    }}>
                    <Text
                      style={{
                        fontSize: 15,
                        color: colors.Red700,
                        lineHeight: 20,
                      }}>
                      {authorisationText}
                    </Text>
                  </View>
                ) : null}
              </ScrollView>
              <TouchableOpacity
                style={{margin: 10}}
                activeOpacity={0.7}
                onPress={this.pgdDialogueButtonTapped.bind(this)}>
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 0,
                    paddingVertical: 16,
                    backgroundColor: colors.ButtonBlue,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 6,
                    shadowOffset: {width: 0, height: 1},
                    shadowRadius: 1,
                    shadowColor: 'black',
                    shadowOpacity: 0.2,
                  }}>
                  <DynamicText
                    style={{
                      fontSize: 16,
                      fontWeight: '700',
                      letterSpacing: -0.39,
                      color: colors.white,
                    }}>
                    {pgdDialogueButtonText}
                  </DynamicText>
                  {sendingPGDAuthorisationLayout}
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
